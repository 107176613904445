<template>
  <div id="app">
    <!--
        <b-navbar>
          <template #brand>
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
            <img id="logo" src="@/assets/CommonThread-Logo.png">
            </b-navbar-item>
          </template>
          <template #start>
            <b-navbar-item href="http://store.commonthreadrecords.com">Store</b-navbar-item>
          </template>
          <template #end>
            <b-navbar-item tag="div">
            </b-navbar-item>
          </template>

        </b-navbar>
        -->
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@mdi/font@5.8.55/css/materialdesignicons.min.css">
  <section class="hero has-background-dark">
    <div class="container">
      <div class="hero-body pt-0 pb-0">
        <img src="@/assets/CommonThread-Logo-horizontal.png">
      </div>
    </div>
  </section>
    <section class="hero is-small is-primary">
      <div class="container">
        <div class="hero-body">
          <a href="http://twitter.com/commonthreadrec" target="_blank">
            <b-icon class="mr-3" icon="twitter" size="is-medium" type="is-dark"></b-icon>
          </a>
          <a href="https://www.facebook.com/CommonThreadRecs" target="_blank">
            <b-icon class="mr-3" icon="facebook" size="is-medium" type="is-dark"></b-icon>
          </a>
          <a href="http://instagram.com/commonthreadrec" target="_blank">
            <b-icon class="mr-3" icon="instagram" size="is-medium" type="is-dark"></b-icon>
          </a>
          <a href="https://www.youtube.com/channel/UCKsyC4CQ0S7zMjTJA-dswGA" target="_blank">
            <b-icon class="mr-3" icon="youtube" size="is-medium" type="is-dark"></b-icon>
          </a>
        </div>
      </div>
    </section>

  <div class="columns">
    <div class="column is-10 is-offset-1">
      <router-view/>
    </div>
  </div>
  <section class="footer has-background-dark">
    <div class="content has-text-info has-text-centered">
      <p>&copy; 2021 Common Thread Records</p>
    </div>
  </section>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      showNav: false
    }
  }
}
</script>

<style scoped>

#logo {
  max-width: 150px;
  max-height: 150px;
}

</style>