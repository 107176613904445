<template>
<div class="container">
            <div class="tile is-ancestor mt-1">
                <div class="tile is-vertical is-10">
                    <div class="tile">
                        <div class="tile is-parent is-8">
                                <article class="tile is-child box">
                                    <p class="title">Featured Release</p>
                                    <div class="box">
                                    <figure class="image is-square">
                                        <img src="@/assets/mah.jpg">
                                    </figure>
                                    </div>
                                    <div class="columns is-centered">
                                        <div class="column is-3">
                                            <b-button class="is-primary" tag="a" href="http://store.commonthreadrecords.com" target="_blank"> Shop Now</b-button>
                                        </div>
                                    </div>
                                </article>
                        </div>
                                <div class="tile is-parent">
                                    <div class="tile is-child box">
                                        <Timeline id="CommonThreadRec" sourceType="profile" :options="{ tweetLimit: '3' }" />
                                    </div>
                                </div>
                    </div>
                    <div class="tile is-parent">
                        <div class="tile is-child box">
                            <b-carousel>
                                            <b-carousel-item>
                                                <figure class="image is-3by1">
                                                    <img src="@/assets/slider1.png">
                                                </figure>
                                            </b-carousel-item>
                                            <b-carousel-item>
                                                <figure class="image is-3by1">
                                                    <img src="@/assets/slider2.png">
                                                </figure>
                                            </b-carousel-item>
                                            <b-carousel-item>
                                                <figure class="image is-3by1">
                                                    <img src="@/assets/slider3.png">
                                                </figure>
                                            </b-carousel-item>
                                            <b-carousel-item>
                                                <figure class="image is-3by1">
                                                    <img src="@/assets/slider4.png">
                                                </figure>
                                            </b-carousel-item>
                            </b-carousel>
                        </div>
                    </div>
                </div>
            </div>

<!--
    <section class="hero">
        <div class="hero-body">
                                    <swiper ref="swiperComponent" :options="swiperOptions" :auto-update="true">
                                            <swiper-slide><img src="@/assets/FN - Under The Clouds EP.jpeg"></swiper-slide>
                                            <swiper-slide><img src="@/assets/FN Promo.jpeg"></swiper-slide>
                                            <swiper-slide><img src="@/assets/SSATM - The Unfortunate EP.jpeg"></swiper-slide>
                                            <swiper-slide><img src="@/assets/SSATM - When In Roam.jpeg"></swiper-slide>
                                            <swiper-slide><img src="@/assets/SSATM Promo.jpeg"></swiper-slide>
                                            <div class="swiper-pagination" slot="pagination"></div>
                                    </swiper>
        </div>
    </section>
-->
</div>
</template>

<script>
    import { Timeline } from 'vue-tweet-embed'

    export default {
        name: 'MainContent',
        components: {
            Timeline
        }
    }
</script>

<style scoped>

#featured {
    width: 350px;
    height: 350px;
}


</style>